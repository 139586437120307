
import { PropType, computed, defineComponent, getCurrentInstance, nextTick, onMounted, ref, watch } from 'vue'
import * as _ from 'lodash'
import TextField from '../../inputs/TextField.vue'
import CustomActivatorSelect from '../../inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../inputs/BaseInputOutlined.vue'
import api from '../../../api/api'
import useRouter from '../../../hooks/useRouter'
import { Project } from '../../../types/main'
import { ScheduledReportData, ScheduledReportTemplate } from '../../../types/scheduledReport'
import { checkInputIsIntNumber } from '@/utils'
import { telephonyService } from '@/services/telephony'
import { checkModuleIsActive, checkRole } from '../../../helpers/roles'
export default defineComponent({
    props: {
        value: {
            type: Object as PropType<ScheduledReportData>,
            required: true,
        },
        scheduledReportTemplates: {
            type: Array as PropType<ScheduledReportTemplate[]>,
            required: true,
        },
    },
    components: { TextField, CustomActivatorSelect, BaseInputOutlined },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const loading = ref(true)
        const { route } = useRouter(root)
        const formIsValid = ref()
        const activeadditionalParams = ref<string[]>([])
        const project = computed<Project>(() => root.$store.getters.projectById(route.value.params.id))

        watch(formIsValid, v => {
            nextTick(() => {
                emit('validation-updated', v)
            })
        })
        const update = (val: any, key: string) => {
            emit('input', Object.assign({}, props.value, { [key]: val }))
        }

        const availableScheduledReportTemplates = computed(() =>
            props.scheduledReportTemplates.map(el => {
                if (
                    el.type === 'weekly' &&
                    !checkModuleIsActive(project.value.id, 'project:scheduledReport:weekly')
                ) {
                    return { ...el, disabled: true, disabledText: 'Не входит в тариф' }
                }
                if (
                    (el.type === null || el.type === 'daily') &&
                    !checkModuleIsActive(project.value.id, 'project:scheduledReport:daily')
                ) {
                    return { ...el, disabled: true, disabledText: 'Не входит в тариф' }
                }
                return el
            })
        )
        const activeAdditionalParams = computed(() => {
            const activeTemplate = props.scheduledReportTemplates.find(el => el.id === props.value.templateId)
            return activeTemplate?.additionalParams || []
        })
        const activeTemplate = computed(() => {
            return props.scheduledReportTemplates.find(el => el.id === props.value.templateId)
        })
        const adminPlanLabel = computed(() => {
            if (activeTemplate.value?.type === 'weekly') {
                return 'План звонков на неделю'
            }
            if (activeTemplate.value?.functionName === 'notification_everyday_report') {
                return 'План коммуникаций на день'
            }
            return 'План звонков на день'
        })
        watch(
            () => props.value.templateId,
            v => {
                activeadditionalParams.value = activeTemplate.value?.additionalParams || []
                if (activeadditionalParams.value.includes('targetUser') && telephonyUsers) {
                    update(
                        _.merge(props.value.additionalParamsValues, {
                            targetUser: _.first(telephonyUsers.value)?.id,
                        }),
                        'additionalParamsValues'
                    )
                }

                nextTick(() => {
                    update(
                        _.omitBy(
                            props.value.additionalParamsValues,
                            (value, key) => !activeadditionalParams.value.includes(key)
                        ),
                        'additionalParamsValues'
                    )
                })
            }
        )

        
        const telephonyUsers = computed(() => {
        const telephonyConnector = telephonyService.getTelephonyConnector(project.value.connectors)
        if (!telephonyConnector) return null

        const uniqueIds = [...new Set(Object.values(telephonyConnector?.usersMapping))]
        const usersMapping = uniqueIds.map(id => ({ id, title: id }))

        if (route.value.params.id === "EX1027GP2") {
            usersMapping.push({
                id : "",
                title : "Все"
            })
        }
        
        return usersMapping
    })

        onMounted(async () => {
            emit('validation-updated', formIsValid.value)
        })
        const clear = () => {
            nextTick(() => {
                emit('validation-updated', false)
            })
        }
        return {
            update,
            loading,
            clear,
            activeAdditionalParams,
            telephonyUsers,
            checkInputIsIntNumber,
            formIsValid,
            adminPlanLabel,
            availableScheduledReportTemplates,
        }
    },
})
