
import { PropType, computed, defineComponent, ref, nextTick, onMounted, getCurrentInstance, watch, onBeforeMount } from 'vue'
import * as _ from 'lodash'
import TextField from '../../inputs/TextField.vue'
import CustomActivatorSelect from '../../inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../inputs/BaseInputOutlined.vue'
import { ScheduledReport, ScheduledReportSendTo } from '../../../types/scheduledReport'
import WhatsappDhAdminSettings from './whatsappDhAdminSettings.vue'
import tgDhAdminSettings from './tgDhAdminSettings.vue'
import cfg from '../../../../config'
import { v4 as uuidv4 } from 'uuid'
export default defineComponent({
    props: {
        value: {
            type: Object as PropType<ScheduledReport>,
            required: true,
        },
    },
    components: {
        TextField,
        CustomActivatorSelect,
        BaseInputOutlined,
        WhatsappDhAdminSettings,
        tgDhAdminSettings,
    },
    setup(props, { emit }) {
        const disableText = ref(false)
        watch(props, (props)=>{
            disableText.value = props.value.templateId === cfg.repostStaffId;
        })
        const root = getCurrentInstance()!.proxy
        const update = (val: any[]) => {
            emit('input', Object.assign({}, props.value, { sendTo: val }))
            nextTick(() => {
                emit('validation-updated', Boolean(props.value.sendTo.length))
            })
        }
        const phoneNumbers = computed(() => props.value.sendTo.filter(el => el.type === 'phone'))
        const isAdmin = computed(() => cfg.adminsWhitelist.includes((root as any).$auth.user.sub))
        const addPhoneNumber = () => {
            const newVal = props.value.sendTo.concat({ id: uuidv4(), type: 'phone', value: '' })
            update(newVal)
        }
        const removePhoneNumber = (phoneNumber: ScheduledReportSendTo) => {
            if (!phoneNumber.id) {
                const newVal = props.value.sendTo.filter(el => (el.value !== phoneNumber.value === !el.id)) 
                update(newVal)
            } else {
                let copyVal = [...props.value.sendTo]
                copyVal = copyVal.filter(el => el.id !== phoneNumber.id)
                console.log(phoneNumber.id)
                update(copyVal)
            }
            
        }
        const isValid = () => {
            if (props.value.sendChannels.includes('whatsappBasis')) {
                return phoneNumbers.value.some(el => el.value) || disableText.value
            }
            if (props.value.sendChannels.includes('whatsappDh')) {
                return true
            }
            return true
        }

        const updateValidation = () => {
            nextTick(() => {
                emit('validation-updated', isValid())
            })
        }

        const clear = () => {
            if (props.value.sendChannels.includes('whatsappDh') || props.value.sendChannels.includes('tgDh'))
            (root.$children as any)[0].clear()
        }
        const checkPhoneId = () => {
            const newVal = [...props.value.sendTo];
            newVal.forEach((el, i) => {
                if (!el.id) {
                    newVal[i].id = uuidv4()
                }
            })
            update(newVal)
        }

        onBeforeMount(() => {
            checkPhoneId()
        })

        onMounted(() => {
            if (props.value.sendChannels.includes('whatsappBasis') && props.value.sendTo.length === 0) {
                addPhoneNumber()
            }
            updateValidation()
        })

        return { update, clear,phoneNumbers, addPhoneNumber, removePhoneNumber, updateValidation, isAdmin, disableText }
    }
})
